<template>
    <b-container v-if="merchant">
        <h3 class="mt-3">{{ merchant.id ? '修改商戶' : '建立商戶' }}</h3>
        <b-form class="mt-3" @submit.stop.prevent="onSubmit">
            <h5>內容</h5>
            <b-form-group label="代號">
                <b-form-input v-model="merchant.code" required></b-form-input>
            </b-form-group>
            <b-form-group label="名稱">
                <b-form-input v-model="merchant.name" required></b-form-input>
            </b-form-group>

            <b-row>
                <b-col>
                    <b-form-group>
                        <b-button block @click="$router.go(-1)">返回</b-button>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group>
                        <b-button type="submit" variant="primary" block>儲存</b-button>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </b-container>
</template>

<script>
import { Merchant } from '@/models'

export default {
    data() {
        return {
            action: this.$route.params.action,
            merchant: null,
        }
    },

    async created() {
        this.merchant = this.action == 'create' ? Merchant() : await this.$store.dispatch('getDocument', { col: 'merchants', id: this.$route.params.id })
        if (!this.merchant) return this.$router.push('/merchants')
    },

    methods: {
        async onSubmit() {
            const res = await this.$store.dispatch(this.action == 'create' ? 'createDocument' : 'updateDocument', { col: 'merchants', doc: this.merchant })
            this.$root.$bvToast.toast(`${this.action == 'create' ? '新增' : '更新'}${res ? '成功' :'失敗'}`, { title: res ? '成功' :'失敗', variant: res ? 'success' : 'danger' })
            return this.$router.push('/merchants')
        }
    }
}
</script>